import { graphql } from 'gatsby';
import styled from 'styled-components';
import React from 'react';
import ContentfulDynamicHero from 'components/Contentful/ContentfulDynamicHero';
import IconCardsSection from 'components/Sections/IconCardsSection';
import Contentful2ColumnLayout from 'components/Contentful/Contentful2ColumnLayout';
import SEO, { createSEO } from 'components/Core/SEO';
import WebsiteLayout from 'layouts/websiteLayout';

const LegalHubPage = (props) => {
  const dynamicHero = props.data.contentfulDynamicHero;
  const demoForm = props.data.contentful2ColumnLayout;
  const cardsBlock = props.data.contentfulCardsBlock;
  const seo = createSEO(props.data.contentfulSeo, props);

  return (
    <WebsiteLayout hero={dynamicHero}>
      <SEO {...seo} />
      <ToolboxDynamicHero {...dynamicHero} images={[]} />
      <ToolboxIconCardsSection
        title={cardsBlock.title}
        cards={cardsBlock.cards}
        key={cardsBlock.id}
        cols={3}
      />
      <Contentful2ColumnLayout {...demoForm} pageType="normal" />
    </WebsiteLayout>
  );
};

export default LegalHubPage;

export const LegalHubPageQuery = graphql`
  query LegalHubPageQuery {
    contentfulSeo(internalName: { eq: "Legal" }) {
      ...Seo
    }

    contentfulDynamicHero(contentful_id: { eq: "4NAxSEcr8ubhCJHLLUSyYb" }) {
      ...DynamicHero
    }

    contentfulCardsBlock(contentful_id: { eq: "23O7JgwHxQN9IzJSpsmATa" }) {
      ...CardsBlock
    }

    contentful2ColumnLayout(contentful_id: { eq: "6eGRiTW0cZlDmpyQNooRwX" }) {
      ...TwoColumnLayout
    }
  }
`;

const ToolboxDynamicHero = styled(ContentfulDynamicHero)`
  padding-top: 180px;
  padding-bottom: 50px;

  @media (max-width: 991px) {
    padding-top: 100px;
  }

  @media (max-width: 575px) {
    padding-bottom: 30px;
  }
`;

const ToolboxIconCardsSection = styled(IconCardsSection)`
  .section-industry-card--container {
    padding-top: 0;

    .section-industry-card--card-content {
      margin-top: -60px;
    }
  }

  @media (max-width: 767px) {
    .section-industry-card--container {
      .section-industry-card--card-content {
        margin-top: -80px;
      }
    }
  }
`;
